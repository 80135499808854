// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")

import "./bootstrap_custom.js";
import "./common/notice/notice"
import "./common/sweetalert/sweetalert"
import "@fortawesome/fontawesome-free/css/all"
import "./common/cookie/cookieconsent.min.js";
import "./common/owl-carousel/owl.carousel.min.js"
import "./common/owl-carousel/index.js"
import "./common/validation/validation"
import "./common/select2/front-select"
import "./common/imagecoo/imagecoo"
import "@nathanvda/cocoon";
import 'lightbox2/dist/js/lightbox'
import 'lightbox2/dist/css/lightbox.min.css';
import 'jquery.qrcode';
import './common/qrcode/index.js'
import "./custom.js";
import './vue.min.js'
import("../styles/style.scss");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
