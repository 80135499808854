import $ from 'jquery'
import cropper from 'cropperjs'
import "./cropper.min.css"
import "./imagecoo.scss"

//var root;
//root = typeof exports !== "undefined" && exports !== null ? exports : this;

window.CROP = function(uid, img, ratio) {
  var cropimg, $btn_crop, $btn_edit, $btn_finish, $btn_remove, $btn_upload, $image_crop, $image_url, $remove, _id, edit, edit_0_mode, edit_1_mode, finish, image, inputImage, options;
  options = {
    aspectRatio: ratio,
    zoomOnWheel: false,
    zoomOnTouch: false
  };
  _id = "#" + uid + " ";
  image = $(_id + "img.image");
  inputImage = $(_id + '.inputImage');
  $btn_edit = $(_id + '.edit');
  $btn_crop = $(_id + '.crop');
  $btn_finish = $(_id + '.finish');
  $btn_upload = $(_id + '.upload');
  $btn_remove = $(_id + '.remove');
  $image_crop = $(_id + '.image-crop');
  $image_url = $(_id + '.image_url');
  $remove = $('.remove_' + img);
  edit_1_mode = function() {
    $btn_edit.hide();
    $btn_crop.show();
    $btn_finish.show();
    $btn_upload.hide();
    return $btn_remove.hide();
  };
  edit_0_mode = function() {
    $btn_edit.show();
    $btn_crop.hide();
    $btn_finish.hide();
    $btn_upload.show();
    if (image.attr('src') === void 0) {
      $image_crop.hide();
      $btn_remove.hide();
      return $btn_edit.hide();
    } else {
      $btn_remove.show();
      return $btn_edit.show();
    }
  };
  edit_0_mode();
  finish = function() {
    return cropimg.destroy();
  };
  edit = function() {
    inputImage.addClass('hide');
    const cropperImage = document.getElementById($(image).attr("id"));
    cropimg = new cropper(cropperImage, options);
    return cropimg;
  };
  if (window.FileReader) {
    $("body").on("dragover dragenter", ".inputImage", function(e){
      $(this).parents(".dropzone").addClass("is-dragover");
    });
    
    $("body").on('dragleave dragend drop', ".inputImage", function() {
      $(this).parents(".dropzone").removeClass('is-dragover');
    })  

    inputImage.change(function() {
      var inputItem = this;
      var getSize = inputItem.files[0].size/1024/1024;

      if(getSize >= 10){
        inputImage.parents(".dropzone").addClass("danger");

        var dangerHtml = '<div class="help-block text-danger"><ul role="alert"><li>檔案超過10MB</li></ul></div>';
        if(inputImage.parents(".form-group").find(".help-block").length == 0){
          inputImage.parents(".form-group").append(dangerHtml);
        }
      }else{
          if(inputImage.parents(".form-group").find(".help-block").length > 0){
            inputImage.parents(".form-group").find(".help-block").remove();
          }

          inputImage.parents(".dropzone").addClass("d-none");
          var file, fileReader, files;
          fileReader = new FileReader();
          files = this.files;
          if (!files.length) {
            return;
          }
          file = files[0];
          if (/^image\/\w+$/.test(file.type)) {
            fileReader.readAsDataURL(file);
            return fileReader.onload = function() {
              inputImage.val("");
              $image_url.val(this.result);
              image.attr('src', this.result);
              $remove.val(0);
              $image_crop.show();
              return edit_0_mode();
            };
          } else {
            return showMessage('Please choose an image file.');
          }
      } // end if getSize
    });
  } else {
    inputImage.addClass('hide');
  }
  $btn_crop.click(function() {
    var _image;
    //_image = (image.cropper('getCroppedCanvas')).toDataURL();
    _image = (cropimg.getCroppedCanvas().toDataURL());
    
    $(image).attr('src', _image);
    $image_url.val(_image);
    finish();
    return edit_0_mode();
  });
  $btn_finish.click(function() {
    edit_0_mode();
    return finish();
  });
  $btn_edit.click(function() {
    edit_1_mode();
    return edit();
  });
  return $btn_remove.click(function() {
    $remove.val(1);
    image.removeAttr('src');
    edit_0_mode();
    inputImage.parents(".dropzone").removeClass("d-none");
    return $image_crop.hide();
  });
};
